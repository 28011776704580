<template>
  <section>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="top">
        <div>
          <p class="recp_title">
            Receipt Information
          </p>
        </div>
        <div>
          <p class="recp_subtitle">
            {{ receipt.name }}
          </p>
          <p class="recp_subtitle">
            Class: {{ receipt.class }}
          </p>
        </div>
      </section>
      <section class="content">
        <div class="grid_2">
          <!-- Receipt Details -->
          <VCard class="recp_card">
            <p class="recp_main_title pb-3">
              Basic Details
            </p>
            <div class="recp_content">
              <div class="span2">
                <p class="recp_main_subtitle">
                  Name
                </p>
                <p class="recp_main_txt">
                  {{ receipt.name }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Admission Number
                </p>
                <p class="recp_main_txt">
                  {{ receipt.admNo }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Class
                </p>
                <p class="recp_main_txt">
                  {{ receipt.class }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Date Of Issue
                </p>
                <p class="recp_main_txt">
                  {{ receipt.date }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Phone Number
                </p>
                <p class="recp_main_txt">
                  {{ receipt.phone }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Month Of
                </p>
                <p class="recp_main_txt">
                  {{ receipt.month }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Session
                </p>
                <p class="recp_main_txt">
                  {{ receipt.session }}
                </p>
              </div>
            </div>
          </VCard>
          <!-- FEES  -->
          <VCard class="recp_card">
            <p class="recp_main_title pb-3">
              Fees Details
            </p>
            <div class="recp_content">
              <div>
                <p class="recp_main_subtitle">
                  Tuition Fee
                </p>
                <p class="recp_main_txt">
                  {{ receipt.tuitionFee }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Late Fee
                </p>
                <p class="recp_main_txt">
                  {{ receipt.lateFee }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Bank Charge
                </p>
                <p class="recp_main_txt">
                  {{ receipt.bankFee }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Miscellaneous
                </p>
                <p class="recp_main_txt">
                  {{ receipt.miscFee }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Total
                </p>
                <p class="recp_main_txt">
                  {{ receipt.totalFee }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Payment Method
                </p>
                <p class="recp_main_txt">
                  {{ receipt.method }}
                </p>
              </div>
              <div class="span3">
                <p class="recp_main_subtitle">
                  Amount In Word
                </p>
                <p class="recp_main_txt">
                  {{ receipt.amountInWord }}
                </p>
              </div>
            </div>
          </VCard>
          <!-- CHEQUE INFO  -->
          <VCard
            v-show="receipt.method === 'Cheque'"
            class="recp_card span2only"
          >
            <p class="recp_main_title pb-3">
              Cheque Details
            </p>
            <div class="recp_content_cq">
              <div>
                <p class="recp_main_subtitle">
                  Cheque Number
                </p>
                <p class="recp_main_txt">
                  {{ receipt.cheque.number }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Date Of Issue
                </p>
                <p class="recp_main_txt">
                  {{ receipt.cheque.date }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Bank Name
                </p>
                <p class="recp_main_txt">
                  {{ receipt.cheque.bank }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Bank Branch
                </p>
                <p class="recp_main_txt">
                  {{ receipt.cheque.branch }}
                </p>
              </div>
              <div>
                <p class="recp_main_subtitle">
                  Depositor
                </p>
                <p class="recp_main_txt">
                  {{ receipt.cheque.depositor }}
                </p>
              </div>
            </div>
          </VCard>
        </div>
      </section>
    </section>
    <VDialog
      v-model="isLoading"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_fs elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="white"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_fs elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_fs elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    noResult: false,
    isLoading: true,
    loadStatus: '',
    retry: 0,
    receipt: {
      name: '',
      admNo: '',
      class: '',
      date: '',
      phone: '',
      month: '',
      session: '',
      tuitionFee: '',
      lateFee: '',
      bankFee: '',
      miscFee: '',
      totalFee: '',
      amountInWord: '',
      method: '',
      cheque: {
        number: '',
        date: '',
        bank: '',
        branch: '',
        depositor: '',
      },
    },
  }),
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      const { id } = this.$route.params;
      await axios.get(`${api}/receipt/${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.receipt = res.data.data;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
      this.isLoading = false;
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
  },

};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Receipt/view';
</style>
